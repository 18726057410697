import React from 'react'
import Layout from 'layouts/en'
import styled from 'styled-components'
import design from 'design'
import {Title, Text, Row, Div, Box, Button, Input, Table, FullWidthBg} from 'components'
import {TryOrSchedule} from "containers/en";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import IslandFinance from "images/PaperworkPassagebig8bitcopy.png";
import Phone from "images/phone.jpg";
import messages from "images/messages.png";
import BALogo from "images/ba-logo.png";
import BareArrow from "images/arrow.inline.svg";
import BareChart from "images/chart.svg";
import Moment from 'react-moment';
import { format } from 'utils'

const formats = format('en')

const Chart = styled(BareChart)`
  width: 100%;
  height: auto;
`
const Arrow = styled(BareArrow)`
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`
// import { space, layout, color, flexbox } from 'styled-system'

// const Test = styled.div`
//   ${color}
//   ${flexbox}
//   ${layout}
// `

export default () => {
  return (
    <Layout>
      {/*<Test display={['block','flex']} bg={['red','green','blue']}>*/}
      {/*  <div>first</div>*/}
      {/*  <div>second</div>*/}
      {/*</Test>*/}
      {/*<Box bg={["violet","lightblue","blue"]}>*/}
      {/*  My background changes at different screen widths.*/}
      {/*</Box>*/}
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={IslandFinance} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Cut your administrative work by half</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[3,6]}>Automatic invoices and receipts generation, your bookkeeping simplified, bird’s-eye view of your finances, and more.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,6]} p={[3,4]} placeholder={`Your email address`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Get Started</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Easy setup • Free for starters • No credit card required • Cancel any time</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div id={'Billing'} display={'flex'} mb={[5,7]}>
          <Title href={'/en/finance#Billing'} mr={7} fontSize={[5,8]} fontWeight={700}>Billing</Title>
          <Title href={'/en/finance#Accounting'} mr={7} fontSize={[5,8]} fontWeight={400}>Accounting</Title>
        </Div>

        <Div id='Prepare' display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/en/finance#Prepare'} mr={[`67px`,6]} mb={4} fontSize={[3,4]} fontWeight={700}>Prepare</Title>
              <Title href={'/en/finance#Release'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
              <Title href={'/en/finance#Supervise'} mr={[`53px`,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Supervise</Title>
              <Title href={'/en/finance#Credit'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Credit</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Streamline the way you create, send, and track your invoices</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Automatic invoice generation from your reservations.</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box bg={'white'} position={'relative'} boxShadow={['none','black']} m={0} mb={[110,0]} mx={[-36,0]} px={[4,5]} py={[5,6]}>
              <Div display={'flex'} justifyContent={'space-between'}>
                <Div>
                  <Text fontWeight={700} fontSize={[3,5]} mb={4}>Customer balance</Text>
                  <Text fontWeight={700} fontSize={[5,6]} mb={[6,7]}>CHF 120.00</Text>
                </Div>
                <Div display={['none','block']}>
                  <Button boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={[1]}>Invoice now</Button>
                </Div>
              </Div>

              <Div mb={[6,7]}>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>Payments</Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'green'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Paid</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                </Div>
              </Div>

              <Div mb={[6,7]}>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>Invoices</Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'green'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Paid</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                </Div>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 140.00</Text>
                  <Text flexBasis={['auto',110]} color={'red'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Voided</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4,m:15 }} format={formats.MDYH} /></Text>
                </Div>
              </Div>

              <Div>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>Pending Items – CHF 120.00</Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'text'} fontWeight={400} fontSize={[2,3]}><Div display={['none','inline']}>Acupuncture</Div></Text>
                  <Text flexBasis={['100%','33%']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Div display={['inline','none']}>Acupuncture</Div></Text>
                </Div>
              </Div>

              <Box bg={'white'} px={4} py={5} boxShadow={'black'} position={'absolute'} bottom={[-90,-46]} right={[-24,-16]} width={300}>
                <Div display={'flex'} mb={3}>
                  <Text flexBasis={75} fontSize={[3,4]} fontWeight={700} color={'primary'}>If</Text>
                  <Text fontSize={[3,4]} fontWeight={700}>Pending Items is 8</Text>
                </Div>
                <Div display={'flex'}>
                  <Text flexBasis={75} fontSize={[3,4]} fontWeight={700} color={'primary'}>Then</Text>
                  <Text fontSize={[3,4]} fontWeight={700}>Prepare Invoice</Text>
                </Div>
              </Box>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Adaptive workflow</Title>
            <Text fontSize={[2,4]}>Automate invoice generation with workflows. Define whether you bill per reservation, on a monthly basis, …</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Multi-standards</Title>
            <Text fontSize={[2,4]}>Define your invoicing standard. Support for Swiss VAT, and Tarif 590 for therapists.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Quote Management</Title>
            <Text fontSize={[2,4]}>Prepare tailor-made quotes for your customers within minutes.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[6,6]} pt={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/en/finance#Prepare'} mr={[`63px`,6]} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Prepare</Title>
            <Title id='Release' href={'/en/finance#Release'} mr={6} mb={4} fontSize={[3,4]} color={'white'} fontWeight={700}>Release</Title>
            <Title href={'/en/finance#Supervise'} mr={6} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Supervise</Title>
            <Title href={'/en/finance#Credit'} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Credit</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Build trust and look like a pro</Title>
          <Text mb={[]} fontSize={[3,4]}>Effortlessly send professional invoices across multiple channels</Text>
          <Div mx={[-48,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1} mb={6}>
              <Title mb={[3,5]} fontSize={[3,6]}>Instant notifications</Title>
              <Box boxShadow={'primaryDark'} position={'relative'} width={375} height={812} bg={'white'} backgroundImage={`url('${Phone}')`} backgroundSize={'cover'} m={0} mx={[0,8]} p={[4,6]}>
                <Div position={'absolute'} top={14} left={14} fontSize={15}>
                  <Moment format={formats.HM} />
                </Div>
                <Div position={'absolute'} top={125} right={0} left={0} fontSize={80}>
                  <Moment format={formats.HM} />
                </Div>
                <Div position={'absolute'} top={210} right={0} left={0} fontSize={22}>
                  <Moment format={formats.dDM}/>
                </Div>
                <Div position={'absolute'} top={264} left={20} fontSize={28}>
                  Today
                </Div>
                <Div p={12} position={'absolute'} width={359} borderRadius={12} bg={`rgba(255,255,255,.5)`} top={301} left={8}>
                  <Div fontSize={15} color={`rgba(0,0,0,.4)`} display={'flex'} justifyContent={'space-between'}>
                    <Div display={'flex'}>
                      <img width={20} height={20} src={messages} alt=""/> <Text ml={`7px`}>Message</Text>
                    </Div>
                    <Text>5min ago</Text>
                  </Div>
                  <Text color={'text'} mt={`8px`} textAlign={'left'}>We are pleased to inform you that your “Blossom Acupuncture” invoice is now available. Download it: https://stairl.in/Fo0B4R - Stairlin</Text>
                </Div>
              </Box>
            </Div>
            <Div display={['none','block']} flex={1}>
              <Title mb={[3,5]} fontSize={[3,6]}>Beautifully designed invoices</Title>
              <Box boxShadow={'primaryDark'} color={'text'} textAlign={'left'} bg={'white'} height={812} m={0} p={[4,6]}>
                <Div mb={7} display={'flex'}>
                  <img width={64} height={64} src={BALogo} alt=""/>
                  <Div ml={4}>
                    <Text fontSize={6} fontWeight={700}>Invoice</Text>
                    <Text fontSize={4}>Blossom Acupuncture</Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Customer</Text>
                    <Text fontSize={3}>John Appleseed
                      Infinite Loop 1
                      8003 Zürich</Text>
                  </Div>
                  <Div ml={4} flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Business</Text>
                    <Text fontSize={3}>Blossom Acupuncture
                      Infinite Loop 1
                      1003 Lausanne</Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Date</Text>
                    <Text fontSize={3}><Moment format={formats.DMY} /></Text>
                  </Div>
                  <Div ml={4} flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Due Date</Text>
                    <Text fontSize={3}><Moment add={{ d:30 }} format={formats.DMY} /></Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Reference</Text>
                    <Text fontSize={3}>IE-86-376-655</Text>
                  </Div>
                </Div>
                <Table width={'100%'}>
                  <Table.Tr>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2}>Description</Table.Th>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2}>Duration</Table.Th>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>Total (CHF)</Table.Th>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>Acupuncture</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>1h</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>120.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>Subtotal</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>120.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>VAT<br/>0% of 120.00 (incl.)</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'} style={{ verticalAlign: 'bottom' }}>0.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} fontWeight={700}>Grand Total</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'} fontWeight={700}>120.00</Table.Td>
                  </Table.Tr>
                </Table>
              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Multi-channel notifications</Title>
              <Text fontSize={[2,4]}>Send invoice across multiple channels, all in one place. Support includes email, SMS, and WhatsApp.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Custom invoices for your business</Title>
              <Text fontSize={[2,4]}>Upload your logo and add personalised messages — all in just a few easy steps.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Multi-currency support</Title>
              <Text fontSize={[2,4]}>Bill your customers in 24 different currencies. Support includes USD, EUR, CHF,  GBP, JPY, and many others.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Supervise' mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/finance#Prepare'} ml={[0,6]} mr={[`66px`,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Prepare</Title>
                <Title href={'/en/finance#Release'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
                <Title href={'/en/finance#Supervise'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={700}>Supervise</Title>
                <Title href={'/en/finance#Credit'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Credit</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Never miss a payment</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Improve efficiency and stop chasing down payments with invoice tracking.</Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={5} fontWeight={700}>Recent activities</Text>
                  <Button display={['none','block']} color={'white'} bg={'primary'} boxShadow={'primary'}>View All</Button>
                </Div>

                <Div>
                  <Div>
                    <Div mt={7}>
                      <Div display={'flex'}>
                        <Div>
                          <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.primary}`}>
                            <Div height={[40,47]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            <Div width={20} height={20} borderRadius={'50%'} border={`2px solid ${design.color.white}`}>
                              <Div width={16} height={16} borderRadius={'50%'} bg={`primary`}></Div>
                            </Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Invoice settled</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            <Div height={[63,46]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>CHF 120.00 paid by John Appleseed</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            <Div height={[63,46]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Sent to John Appleseed on June 5, 2019</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4,m:25 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Invoice created from reservation</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:2,h:6,m:11 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>

              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Clear and transparent tracking</Title>
              <Text fontSize={[2,4]}>Check the status of your invoices and payments from anywhere. Instantly know which invoices are paid or past due.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Offline Payments</Title>
              <Text fontSize={[2,4]}>Manage and track offline payments so that all your information stays in one place.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Payment reminders</Title>
              <Text fontSize={[2,4]}>Easily resend invoices to your customers as a gentle reminder.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Credit' mt={[6,8]}>
          <Div display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/finance#Prepare'} mr={[`63px`,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Prepare</Title>
                <Title href={'/en/finance#Release'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
                <Title href={'/en/finance#Supervise'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Supervise</Title>
                <Title href={'/en/finance#Credit'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Credit</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Untangle the credit snag</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Apply credits, create a credit note, apply excess payment, remove payment, record a refund, write off, or void an invoice.</Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} height={['auto',640]} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Div>
                    <Text fontSize={6} mb={2} fontWeight={700}>CHF 120.00</Text>
                    <Text fontSize={3} color={'green'} fontWeight={700}>Paid</Text>
                  </Div>
                  <Button alignSelf={'flex-start'} display={['none','block']} bg={'primary'} color={'white'} boxShadow={'primary'}>Issue credit note</Button>
                </Div>
                <Text mb={[5,6]} fontSize={[3,4]} mt={[6,7]} fontWeight={700}>Invoice Summary</Text>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Billed To</Text>
                  <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>John Appleseed</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Reference</Text>
                  <Text fontSize={[2,3]}>IE-86-376-655</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Date</Text>
                  <Text fontSize={[2,3]}><Moment subtract={{ d:5 }} format={formats.DMY} /></Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Total</Text>
                  <Text fontSize={[2,3]}>CHF 120.00</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Due Date</Text>
                  <Text fontSize={[2,3]}><Moment add={{ d:25 }} format={formats.DMY} /></Text>
                </Div>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Status</Text>
                  <Text fontSize={[2,3]} color={'green'}>Paid</Text>
                </Div>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} mt={[4,8]} mx={[-36,-48]} color={design.color.white} bg={design.color.primary} p={[`36px`,6]} pt={[6,8]}>
          <FullWidthBg display={['none','block']} />
          <Div display={'flex'} justifyContent={['flex-start','flex-end']} mb={[5,7]}>
            <Title href={'/en/finance#Billing'} ml={[0,7]} fontSize={[5,8]} fontWeight={400} color={'white'}>Billing</Title>
            <Title id={'Accounting'} href={'/en/finance#Accounting'} ml={[7,7]} fontSize={[5,8]} fontWeight={700} color={'white'}>Accounting</Title>
          </Div>

          <Div id='Export' display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/finance#Export'} ml={[0,6]} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Export</Title>
                <Title href={'/en/finance#Analyse'} ml={[`75px`,6]} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Analyse</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Accuracy down to the dime, every time.</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Bid goodbye to accounting Sunday and manual errors.</Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box boxShadow={'primaryDark'} mx={[-36,0]} color={'text'} bg={'white'} m={0} p={[4,6]}>
                <Text fontSize={[5,6]} fontWeight={700} mb={6}>Transactions</Text>
                <Div display={'flex'} flexWrap={'wrap'}>
                  <Div flex={1} position={'relative'}>
                    <Text bg={'white'} px={`2px`} position={'absolute'} top={-10} left={16} fontSize={1} fontWeight={700}>Period</Text>
                    <Input value={`Last quarter`}/>
                    <Div position={'absolute'} right={14} top={14}><Arrow /></Div>
                  </Div>
                  <Button flexBasis={['100%','auto']} bg={'primary'} color={'white'} boxShadow={'primary'} mt={[4,0]} ml={[0,5]}>Export</Button>
                </Div>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Monthly, Quarterly, or Yearly</Title>
              <Text fontSize={[2,4]}>All your payments and invoices are ready to be exported for any given accounting period.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Open standard</Title>
              <Text fontSize={[2,4]}>Exports are in CSV, which can be open on Microsoft Excel, Apple Numbrs, or Google Spreadsheet right off the bat.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>No reconciliation needed</Title>
              <Text fontSize={[2,4]}>Because it is a real bummer when the invoice information on your accounting software does not match the figures on your bank statement. </Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Analyse' mt={[6,8]}>
          <Div display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/en/finance#Export'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Export</Title>
                <Title href={'/en/finance#Analyse'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Analyse</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title display={['block','none']} mb={[4,6]} fontSize={[6,7]}>Forecast with greater predictability</Title>
                <Title display={['none','block']} mb={[4,6]} fontSize={[6,7]}>Forecast with greater predictability by building accurate financial reports on top of your single source of truth.</Title>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} m={0} p={[4,6]} display={'flex'}>
                <Div flex={1}>
                  <Text fontWeight={700} fontSize={[0,4]} mb={[3,4]}>Gross volume</Text>
                  <Text fontWeight={700} fontSize={[2,6]} mb={[4,5]}>CHF 16’200.00</Text>
                  <Text fontWeight={700} fontSize={[0,4]} mb={[3,4]}>Net volume</Text>
                  <Text fontWeight={700} fontSize={[2,6]}>CHF 14’800.00</Text>
                </Div>
                <Div flex={1} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}><Chart /></Div>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
